<template>
  <v-container class="lighten-5 container">

    <v-row class="mb-6" no-gutters>
      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/perfil_03.svg" src="/img/perfil_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Perfil/'+limpiaTexto(noticias_perfil_0_titulo)+'/'+noticias_perfil_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_perfil_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Perfil/'+limpiaTexto(noticias_perfil_1_titulo)+'/'+noticias_perfil_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_perfil_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Perfil/'+limpiaTexto(noticias_perfil_2_titulo)+'/'+noticias_perfil_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_perfil_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Perfil/'+limpiaTexto(noticias_perfil_3_titulo)+'/'+noticias_perfil_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_perfil_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Perfil/'+limpiaTexto(noticias_perfil_4_titulo)+'/'+noticias_perfil_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_perfil_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/el_destape_04.svg" src="/img/el_destape_04.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/El Destape/'+limpiaTexto(noticias_eldestape_0_titulo)+'/'+noticias_eldestape_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_eldestape_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Destape/'+limpiaTexto(noticias_eldestape_1_titulo)+'/'+noticias_eldestape_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_eldestape_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Destape/'+limpiaTexto(noticias_eldestape_2_titulo)+'/'+noticias_eldestape_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_eldestape_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Destape/'+limpiaTexto(noticias_eldestape_3_titulo)+'/'+noticias_eldestape_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_eldestape_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Destape/'+limpiaTexto(noticias_eldestape_4_titulo)+'/'+noticias_eldestape_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_eldestape_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/c5n_03.svg" src="/img/c5n_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/C5N/'+limpiaTexto(noticias_c5n_0_titulo)+'/'+noticias_c5n_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_c5n_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/C5N/'+limpiaTexto(noticias_c5n_1_titulo)+'/'+noticias_c5n_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_c5n_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/C5N/'+limpiaTexto(noticias_c5n_2_titulo)+'/'+noticias_c5n_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_c5n_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/C5N/'+encodeURI(noticias_c5n_3_titulo)+'/'+noticias_c5n_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_c5n_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/C5N/'+encodeURI(noticias_c5n_4_titulo)+'/'+noticias_c5n_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_c5n_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/popular_04.svg" src="/img/popular_04.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Diario Popular/'+encodeURI(noticias_popular_0_titulo)+'/'+noticias_popular_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_popular_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Diario Popular/'+encodeURI(noticias_popular_1_titulo)+'/'+noticias_popular_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_popular_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Diario Popular/'+encodeURI(noticias_popular_2_titulo)+'/'+noticias_popular_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_popular_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Diario Popular/'+encodeURI(noticias_popular_3_titulo)+'/'+noticias_popular_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_popular_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Diario Popular/'+encodeURI(noticias_popular_4_titulo)+'/'+noticias_popular_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_popular_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>


<script>

import axios from "axios";
export default {
  name: "Titulos",
 
  data: () => ({
    medios1: [
      { medio: "ambito" },
      { medio: "clarin" },
      { medio: "lanacion" },
      { medio: "infobae" },
    ],
    medios2: [
      { medio: "cronista" },
      { medio: "cronica" },
      { medio: "baenegocios" },
      { medio: "eldestape" },
    ],

    noticias_perfil_0_id: "",
    noticias_perfil_0_titulo: "",
    noticias_perfil_0_foto: "",

    noticias_perfil_1_id: "",
    noticias_perfil_1_titulo: "",
    noticias_perfil_1_foto: "",

    noticias_perfil_2_id: "",
    noticias_perfil_2_titulo: "",
    noticias_perfil_2_foto: "",

    noticias_perfil_3_id: "",
    noticias_perfil_3_titulo: "",
    noticias_perfil_3_foto: "",

    noticias_perfil_4_id: "",
    noticias_perfil_4_titulo: "",
    noticias_perfil_4_foto: "",

    noticias_eldestape_0_id: "",
    noticias_eldestape_0_titulo: "",
    noticias_eldestape_0_foto: "",

    noticias_eldestape_1_id: "",
    noticias_eldestape_1_titulo: "",
    noticias_eldestape_1_foto: "",

    noticias_eldestape_2_id: "",
    noticias_eldestape_2_titulo: "",
    noticias_eldestape_2_foto: "",

    noticias_eldestape_3_id: "",
    noticias_eldestape_3_titulo: "",
    noticias_eldestape_3_foto: "",

    noticias_eldestape_4_id: "",
    noticias_eldestape_4_titulo: "",
    noticias_eldestape_4_foto: "",

    noticias_c5n_0_id: "",
    noticias_c5n_0_titulo: "",
    noticias_c5n_0_foto: "",

    noticias_c5n_1_id: "",
    noticias_c5n_1_titulo: "",
    noticias_c5n_1_foto: "",

    noticias_c5n_2_id: "",
    noticias_c5n_2_titulo: "",
    noticias_c5n_2_foto: "",

    noticias_c5n_3_id: "",
    noticias_c5n_3_titulo: "",
    noticias_c5n_3_foto: "",

    noticias_c5n_4_id: "",
    noticias_c5n_4_titulo: "",
    noticias_c5n_4_foto: "",

    noticias_popular_0_id: "",
    noticias_popular_0_titulo: "",
    noticias_popular_0_foto: "",

    noticias_popular_1_id: "",
    noticias_popular_1_titulo: "",
    noticias_popular_1_foto: "",

    noticias_popular_2_id: "",
    noticias_popular_2_titulo: "",
    noticias_popular_2_foto: "",

    noticias_popular_3_id: "",
    noticias_popular_3_titulo: "",
    noticias_popular_3_foto: "",

    noticias_popular_4_id: "",
    noticias_popular_4_titulo: "",
    noticias_popular_4_foto: "",
  }),

  methods: {
    trunca_cadena(texto) {
      if (texto.length > 100) {
        texto = texto.substring(0, 100) + "...";
      }
      return texto;
    },
    limpiaTexto(titulo) {
      titulo = titulo.toLowerCase();      
      titulo = titulo.replace(/[^\w\d\s]/g, "");
      titulo = titulo.replace(/[\s]/g, "-");
      titulo = titulo.substring(0, 100);
      return titulo;
    },
  },

  async mounted() {
    try {
      const res = await axios.get("https://titulos.com.ar/titulos_004.json");

      this.noticias_perfil_0_id = res.data.resultados.perfil[0][0][0];
      this.noticias_perfil_0_titulo = res.data.resultados.perfil[0][0][1];
      this.noticias_perfil_0_foto = res.data.resultados.perfil[0][0][3];

      this.noticias_perfil_1_id = res.data.resultados.perfil[0][1][0];
      this.noticias_perfil_1_titulo = res.data.resultados.perfil[0][1][1];
      this.noticias_perfil_1_foto = res.data.resultados.perfil[0][1][3];

      this.noticias_perfil_2_id = res.data.resultados.perfil[0][2][0];
      this.noticias_perfil_2_titulo = res.data.resultados.perfil[0][2][1];
      this.noticias_perfil_2_foto = res.data.resultados.perfil[0][2][3];

      this.noticias_perfil_3_id = res.data.resultados.perfil[0][3][0];
      this.noticias_perfil_3_titulo = res.data.resultados.perfil[0][3][1];
      this.noticias_perfil_3_foto = res.data.resultados.perfil[0][3][3];

      this.noticias_perfil_4_id = res.data.resultados.perfil[0][4][0];
      this.noticias_perfil_4_titulo = res.data.resultados.perfil[0][4][1];
      this.noticias_perfil_4_foto = res.data.resultados.perfil[0][4][3];

      this.noticias_eldestape_0_id = res.data.resultados.eldestape[0][0][0];
      this.noticias_eldestape_0_titulo = res.data.resultados.eldestape[0][0][1];
      this.noticias_eldestape_0_foto = res.data.resultados.eldestape[0][0][3];

      this.noticias_eldestape_1_id = res.data.resultados.eldestape[0][1][0];
      this.noticias_eldestape_1_titulo = res.data.resultados.eldestape[0][1][1];
      this.noticias_eldestape_1_foto = res.data.resultados.eldestape[0][1][3];

      this.noticias_eldestape_2_id = res.data.resultados.eldestape[0][2][0];
      this.noticias_eldestape_2_titulo = res.data.resultados.eldestape[0][2][1];
      this.noticias_eldestape_2_foto = res.data.resultados.eldestape[0][2][3];

      this.noticias_eldestape_3_id = res.data.resultados.eldestape[0][3][0];
      this.noticias_eldestape_3_titulo = res.data.resultados.eldestape[0][3][1];
      this.noticias_eldestape_3_foto = res.data.resultados.eldestape[0][3][3];

      this.noticias_eldestape_4_id = res.data.resultados.eldestape[0][4][0];
      this.noticias_eldestape_4_titulo = res.data.resultados.eldestape[0][4][1];
      this.noticias_eldestape_4_foto = res.data.resultados.eldestape[0][4][3];

      this.noticias_c5n_0_id = res.data.resultados.c5n[0][0][0];
      this.noticias_c5n_0_titulo = res.data.resultados.c5n[0][0][1];
      this.noticias_c5n_0_foto = res.data.resultados.c5n[0][0][3];

      this.noticias_c5n_1_id = res.data.resultados.c5n[0][1][0];
      this.noticias_c5n_1_titulo = res.data.resultados.c5n[0][1][1];
      this.noticias_c5n_1_foto = res.data.resultados.c5n[0][1][3];

      this.noticias_c5n_2_id = res.data.resultados.c5n[0][2][0];
      this.noticias_c5n_2_titulo = res.data.resultados.c5n[0][2][1];
      this.noticias_c5n_2_foto = res.data.resultados.c5n[0][2][3];

      this.noticias_c5n_3_id = res.data.resultados.c5n[0][3][0];
      this.noticias_c5n_3_titulo = res.data.resultados.c5n[0][3][1];
      this.noticias_c5n_3_foto = res.data.resultados.c5n[0][3][3];

      this.noticias_c5n_4_id = res.data.resultados.c5n[0][4][0];
      this.noticias_c5n_4_titulo = res.data.resultados.c5n[0][4][1];
      this.noticias_c5n_4_foto = res.data.resultados.c5n[0][4][3];

      this.noticias_popular_0_id = res.data.resultados.popular[0][0][0];
      this.noticias_popular_0_titulo = res.data.resultados.popular[0][0][1];
      this.noticias_popular_0_foto = res.data.resultados.popular[0][0][3];

      this.noticias_popular_1_id = res.data.resultados.popular[0][1][0];
      this.noticias_popular_1_titulo = res.data.resultados.popular[0][1][1];
      this.noticias_popular_1_foto = res.data.resultados.popular[0][1][3];

      this.noticias_popular_2_id = res.data.resultados.popular[0][2][0];
      this.noticias_popular_2_titulo = res.data.resultados.popular[0][2][1];
      this.noticias_popular_2_foto = res.data.resultados.popular[0][2][3];

      this.noticias_popular_3_id = res.data.resultados.popular[0][3][0];
      this.noticias_popular_3_titulo = res.data.resultados.popular[0][3][1];
      this.noticias_popular_3_foto = res.data.resultados.popular[0][3][3];

      this.noticias_popular_4_id = res.data.resultados.popular[0][4][0];
      this.noticias_popular_4_titulo = res.data.resultados.popular[0][4][1];
      this.noticias_popular_4_foto = res.data.resultados.popular[0][4][3];

      //console.log(this.noticias);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>



<style scoped>
.v-card__subtitle,
.v-card__text {
  padding: 25px !important;
}

.v-card__title {
  padding: 15px !important;
}
</style>