<template>
  <v-container class="lighten-5 container">

    <v-row class="mb-6" no-gutters>
      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/paparazzi_04.svg" src="/img/paparazzi_04.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Paparazzi/'+limpiaTexto(noticias_paparazzi_0_titulo)+'/'+noticias_paparazzi_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_paparazzi_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Paparazzi/'+limpiaTexto(noticias_paparazzi_1_titulo)+'/'+noticias_paparazzi_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_paparazzi_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Paparazzi/'+limpiaTexto(noticias_paparazzi_2_titulo)+'/'+noticias_paparazzi_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_paparazzi_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Paparazzi/'+limpiaTexto(noticias_paparazzi_3_titulo)+'/'+noticias_paparazzi_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_paparazzi_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Paparazzi/'+limpiaTexto(noticias_paparazzi_4_titulo)+'/'+noticias_paparazzi_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_paparazzi_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/ciudad_003.svg" src="/img/ciudad_003.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Ciudad Magazine/'+limpiaTexto(noticias_ciudad_0_titulo)+'/'+noticias_ciudad_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ciudad_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Ciudad Magazine/'+limpiaTexto(noticias_ciudad_1_titulo)+'/'+noticias_ciudad_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ciudad_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Ciudad Magazine/'+limpiaTexto(noticias_ciudad_2_titulo)+'/'+noticias_ciudad_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ciudad_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Ciudad Magazine/'+limpiaTexto(noticias_ciudad_3_titulo)+'/'+noticias_ciudad_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ciudad_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Ciudad Magazine/'+limpiaTexto(noticias_ciudad_4_titulo)+'/'+noticias_ciudad_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ciudad_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/ratingcero_03.svg" src="/img/ratingcero_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Rating Cero/'+limpiaTexto(noticias_ratingcero_0_titulo)+'/'+noticias_ratingcero_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ratingcero_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Rating Cero/'+limpiaTexto(noticias_ratingcero_1_titulo)+'/'+noticias_ratingcero_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ratingcero_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Rating Cero/'+limpiaTexto(noticias_ratingcero_2_titulo)+'/'+noticias_ratingcero_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ratingcero_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Rating Cero/'+limpiaTexto(noticias_ratingcero_3_titulo)+'/'+noticias_ratingcero_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ratingcero_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Rating Cero/'+limpiaTexto(noticias_ratingcero_4_titulo)+'/'+noticias_ratingcero_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ratingcero_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/teleshow_003.svg" src="/img/teleshow_003.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Teleshow/'+limpiaTexto(noticias_teleshow_0_titulo)+'/'+noticias_teleshow_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_teleshow_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Teleshow/'+limpiaTexto(noticias_teleshow_1_titulo)+'/'+noticias_teleshow_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_teleshow_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Teleshow/'+limpiaTexto(noticias_teleshow_2_titulo)+'/'+noticias_teleshow_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_teleshow_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Teleshow/'+limpiaTexto(noticias_teleshow_3_titulo)+'/'+noticias_teleshow_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_teleshow_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Teleshow/'+limpiaTexto(noticias_teleshow_4_titulo)+'/'+noticias_teleshow_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_teleshow_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

    </v-row>
        <!-- <div align="center">
<Adsense text-justify-center
    data-ad-client="ca-pub-1704998024151646"
    data-ad-slot="2204674867">
    </Adsense>
     </div> -->
  </v-container>
</template>


<script>

import axios from "axios";
export default {
  name: "Titulos",
 
  data: () => ({
    medios1: [
      { medio: "ambito" },
      { medio: "clarin" },
      { medio: "lanacion" },
      { medio: "infobae" },
    ],
    medios2: [
      { medio: "cronista" },
      { medio: "cronica" },
      { medio: "baenegocios" },
      { medio: "eldestape" },
    ],

    noticias_paparazzi_0_id: "",
    noticias_paparazzi_0_titulo: "",
    noticias_paparazzi_0_foto: "",

    noticias_paparazzi_1_id: "",
    noticias_paparazzi_1_titulo: "",
    noticias_paparazzi_1_foto: "",

    noticias_paparazzi_2_id: "",
    noticias_paparazzi_2_titulo: "",
    noticias_paparazzi_2_foto: "",

    noticias_paparazzi_3_id: "",
    noticias_paparazzi_3_titulo: "",
    noticias_paparazzi_3_foto: "",

    noticias_paparazzi_4_id: "",
    noticias_paparazzi_4_titulo: "",
    noticias_paparazzi_4_foto: "",

    noticias_ciudad_0_id: "",
    noticias_ciudad_0_titulo: "",
    noticias_ciudad_0_foto: "",

    noticias_ciudad_1_id: "",
    noticias_ciudad_1_titulo: "",
    noticias_ciudad_1_foto: "",

    noticias_ciudad_2_id: "",
    noticias_ciudad_2_titulo: "",
    noticias_ciudad_2_foto: "",

    noticias_ciudad_3_id: "",
    noticias_ciudad_3_titulo: "",
    noticias_ciudad_3_foto: "",

    noticias_ciudad_4_id: "",
    noticias_ciudad_4_titulo: "",
    noticias_ciudad_4_foto: "",

    noticias_ratingcero_0_id: "",
    noticias_ratingcero_0_titulo: "",
    noticias_ratingcero_0_foto: "",

    noticias_ratingcero_1_id: "",
    noticias_ratingcero_1_titulo: "",
    noticias_ratingcero_1_foto: "",

    noticias_ratingcero_2_id: "",
    noticias_ratingcero_2_titulo: "",
    noticias_ratingcero_2_foto: "",

    noticias_ratingcero_3_id: "",
    noticias_ratingcero_3_titulo: "",
    noticias_ratingcero_3_foto: "",

    noticias_ratingcero_4_id: "",
    noticias_ratingcero_4_titulo: "",
    noticias_ratingcero_4_foto: "",

    noticias_teleshow_0_id: "",
    noticias_teleshow_0_titulo: "",
    noticias_teleshow_0_foto: "",

    noticias_teleshow_1_id: "",
    noticias_teleshow_1_titulo: "",
    noticias_teleshow_1_foto: "",

    noticias_teleshow_2_id: "",
    noticias_teleshow_2_titulo: "",
    noticias_teleshow_2_foto: "",

    noticias_teleshow_3_id: "",
    noticias_teleshow_3_titulo: "",
    noticias_teleshow_3_foto: "",

    noticias_teleshow_4_id: "",
    noticias_teleshow_4_titulo: "",
    noticias_teleshow_4_foto: "",
  }),

  methods: {
    trunca_cadena(texto) {
      if (texto.length > 100) {
        texto = texto.substring(0, 100) + "...";
      }
      return texto;
    },
    limpiaTexto(titulo) {
      titulo = titulo.toLowerCase();      
      titulo = titulo.replace(/[^\w\d\s]/g, "");
      titulo = titulo.replace(/[\s]/g, "-");
      titulo = titulo.substring(0, 100);
      return titulo;
    },
  },

  async mounted() {
    try {
      const res = await axios.get("https://titulos.com.ar/titulos_005.json");

      this.noticias_paparazzi_0_id = res.data.resultados.paparazzi[0][0][0];
      this.noticias_paparazzi_0_titulo = res.data.resultados.paparazzi[0][0][1];
      this.noticias_paparazzi_0_foto = res.data.resultados.paparazzi[0][0][3];

      this.noticias_paparazzi_1_id = res.data.resultados.paparazzi[0][1][0];
      this.noticias_paparazzi_1_titulo = res.data.resultados.paparazzi[0][1][1];
      this.noticias_paparazzi_1_foto = res.data.resultados.paparazzi[0][1][3];

      this.noticias_paparazzi_2_id = res.data.resultados.paparazzi[0][2][0];
      this.noticias_paparazzi_2_titulo = res.data.resultados.paparazzi[0][2][1];
      this.noticias_paparazzi_2_foto = res.data.resultados.paparazzi[0][2][3];

      this.noticias_paparazzi_3_id = res.data.resultados.paparazzi[0][3][0];
      this.noticias_paparazzi_3_titulo = res.data.resultados.paparazzi[0][3][1];
      this.noticias_paparazzi_3_foto = res.data.resultados.paparazzi[0][3][3];

      this.noticias_paparazzi_4_id = res.data.resultados.paparazzi[0][4][0];
      this.noticias_paparazzi_4_titulo = res.data.resultados.paparazzi[0][4][1];
      this.noticias_paparazzi_4_foto = res.data.resultados.paparazzi[0][4][3];

      this.noticias_ciudad_0_id = res.data.resultados.ciudad[0][0][0];
      this.noticias_ciudad_0_titulo = res.data.resultados.ciudad[0][0][1];
      this.noticias_ciudad_0_foto = res.data.resultados.ciudad[0][0][3];

      this.noticias_ciudad_1_id = res.data.resultados.ciudad[0][1][0];
      this.noticias_ciudad_1_titulo = res.data.resultados.ciudad[0][1][1];
      this.noticias_ciudad_1_foto = res.data.resultados.ciudad[0][1][3];

      this.noticias_ciudad_2_id = res.data.resultados.ciudad[0][2][0];
      this.noticias_ciudad_2_titulo = res.data.resultados.ciudad[0][2][1];
      this.noticias_ciudad_2_foto = res.data.resultados.ciudad[0][2][3];

      this.noticias_ciudad_3_id = res.data.resultados.ciudad[0][3][0];
      this.noticias_ciudad_3_titulo = res.data.resultados.ciudad[0][3][1];
      this.noticias_ciudad_3_foto = res.data.resultados.ciudad[0][3][3];

      this.noticias_ciudad_4_id = res.data.resultados.ciudad[0][4][0];
      this.noticias_ciudad_4_titulo = res.data.resultados.ciudad[0][4][1];
      this.noticias_ciudad_4_foto = res.data.resultados.ciudad[0][4][3];

      this.noticias_ratingcero_0_id = res.data.resultados.ratingcero[0][0][0];
      this.noticias_ratingcero_0_titulo =
        res.data.resultados.ratingcero[0][0][1];
      this.noticias_ratingcero_0_foto = res.data.resultados.ratingcero[0][0][3];

      this.noticias_ratingcero_1_id = res.data.resultados.ratingcero[0][1][0];
      this.noticias_ratingcero_1_titulo =
        res.data.resultados.ratingcero[0][1][1];
      this.noticias_ratingcero_1_foto = res.data.resultados.ratingcero[0][1][3];

      this.noticias_ratingcero_2_id = res.data.resultados.ratingcero[0][2][0];
      this.noticias_ratingcero_2_titulo =
        res.data.resultados.ratingcero[0][2][1];
      this.noticias_ratingcero_2_foto = res.data.resultados.ratingcero[0][2][3];

      this.noticias_ratingcero_3_id = res.data.resultados.ratingcero[0][3][0];
      this.noticias_ratingcero_3_titulo =
        res.data.resultados.ratingcero[0][3][1];
      this.noticias_ratingcero_3_foto = res.data.resultados.ratingcero[0][3][3];

      this.noticias_ratingcero_4_id = res.data.resultados.ratingcero[0][4][0];
      this.noticias_ratingcero_4_titulo =
        res.data.resultados.ratingcero[0][4][1];
      this.noticias_ratingcero_4_foto = res.data.resultados.ratingcero[0][4][3];

      this.noticias_teleshow_0_id = res.data.resultados.teleshow[0][0][0];
      this.noticias_teleshow_0_titulo = res.data.resultados.teleshow[0][0][1];
      this.noticias_teleshow_0_foto = res.data.resultados.teleshow[0][0][3];

      this.noticias_teleshow_1_id = res.data.resultados.teleshow[0][1][0];
      this.noticias_teleshow_1_titulo = res.data.resultados.teleshow[0][1][1];
      this.noticias_teleshow_1_foto = res.data.resultados.teleshow[0][1][3];

      this.noticias_teleshow_2_id = res.data.resultados.teleshow[0][2][0];
      this.noticias_teleshow_2_titulo = res.data.resultados.teleshow[0][2][1];
      this.noticias_teleshow_2_foto = res.data.resultados.teleshow[0][2][3];

      this.noticias_teleshow_3_id = res.data.resultados.teleshow[0][3][0];
      this.noticias_teleshow_3_titulo = res.data.resultados.teleshow[0][3][1];
      this.noticias_teleshow_3_foto = res.data.resultados.teleshow[0][3][3];

      this.noticias_teleshow_4_id = res.data.resultados.teleshow[0][4][0];
      this.noticias_teleshow_4_titulo = res.data.resultados.teleshow[0][4][1];
      this.noticias_teleshow_4_foto = res.data.resultados.teleshow[0][4][3];

      //console.log(this.noticias);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>



<style scoped>
.v-card__subtitle,
.v-card__text {
  padding: 25px !important;
}

.v-card__title {
  padding: 15px !important;
}
</style>