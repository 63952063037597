<template>
  <div>
    <Palabras />
    <MasLeidas />
    <Noticias1 />
    <Noticias2 />
    <Noticias3 />
    <Noticias4 />
    <Noticias5 />
  </div>
</template>

<script>
import Palabras from "@/components/palabras.vue";
import MasLeidas from "@/components/mas-leidas.vue";
import Noticias1 from "@/components/noticias-1.vue";
import Noticias2 from "@/components/noticias-2.vue";
import Noticias3 from "@/components/noticias-3.vue";
import Noticias4 from "@/components/noticias-4.vue";
import Noticias5 from "@/components/noticias-5.vue";

export default {
  name: "Home",
  components: {
    Palabras,
    MasLeidas,
    Noticias1,
    Noticias2,
    Noticias3,
    Noticias4,
    Noticias5,
  },
};
</script>