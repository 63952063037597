<template>
  <v-container class="lighten-5 container">

    <v-row class="mb-6" no-gutters>
      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/ambito_03.svg" src="/img/ambito_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Ámbito/'+limpiaTexto(noticias_ambito_0_titulo)+'/'+noticias_ambito_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ambito_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Ámbito/'+limpiaTexto(noticias_ambito_1_titulo)+'/'+noticias_ambito_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ambito_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Ámbito/'+limpiaTexto(noticias_ambito_2_titulo)+'/'+noticias_ambito_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ambito_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Ámbito/'+limpiaTexto(noticias_ambito_3_titulo)+'/'+noticias_ambito_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ambito_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Ámbito/'+limpiaTexto(noticias_ambito_4_titulo)+'/'+noticias_ambito_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ambito_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/el_cronista_03.svg" src="/img/el_cronista_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/El Cronista/'+limpiaTexto(noticias_cronista_0_titulo)+'/'+noticias_cronista_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronista_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Cronista/'+limpiaTexto(noticias_cronista_1_titulo)+'/'+noticias_cronista_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronista_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Cronista/'+limpiaTexto(noticias_cronista_2_titulo)+'/'+noticias_cronista_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronista_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Cronista/'+limpiaTexto(noticias_cronista_3_titulo)+'/'+noticias_cronista_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronista_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Cronista/'+limpiaTexto(noticias_cronista_4_titulo)+'/'+noticias_cronista_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronista_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/pagina12_03.svg" src="/img/pagina12_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Página 12/'+limpiaTexto(noticias_pagina12_0_titulo)+'/'+noticias_pagina12_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_pagina12_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Página 12/'+limpiaTexto(noticias_pagina12_1_titulo)+'/'+noticias_pagina12_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_pagina12_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Página 12/'+limpiaTexto(noticias_pagina12_2_titulo)+'/'+noticias_pagina12_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_pagina12_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Página 12/'+limpiaTexto(noticias_pagina12_3_titulo)+'/'+noticias_pagina12_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_pagina12_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Página 12/'+limpiaTexto(noticias_pagina12_4_titulo)+'/'+noticias_pagina12_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_pagina12_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/tn_02.svg" src="/img/tn_02.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/TN/'+limpiaTexto(noticias_tn_0_titulo)+'/'+noticias_tn_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_tn_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/TN/'+limpiaTexto(noticias_tn_1_titulo)+'/'+noticias_tn_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_tn_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/TN/'+limpiaTexto(noticias_tn_2_titulo)+'/'+noticias_tn_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_tn_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/TN/'+limpiaTexto(noticias_tn_3_titulo)+'/'+noticias_tn_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_tn_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/TN/'+limpiaTexto(noticias_tn_4_titulo)+'/'+noticias_tn_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_tn_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

    </v-row>

  </v-container>
</template>


<script>

import axios from "axios";
export default {
  name: "Titulos",

  data: () => ({
    medios1: [
      { medio: "ambito" },
      { medio: "clarin" },
      { medio: "lanacion" },
      { medio: "infobae" },
    ],
    medios2: [
      { medio: "cronista" },
      { medio: "cronica" },
      { medio: "baenegocios" },
      { medio: "eldestape" },
    ],

    noticias_ambito_0_id: "",
    noticias_ambito_0_titulo: "",
    noticias_ambito_0_foto: "",

    noticias_ambito_1_id: "",
    noticias_ambito_1_titulo: "",
    noticias_ambito_1_foto: "",

    noticias_ambito_2_id: "",
    noticias_ambito_2_titulo: "",
    noticias_ambito_2_foto: "",

    noticias_ambito_3_id: "",
    noticias_ambito_3_titulo: "",
    noticias_ambito_3_foto: "",

    noticias_ambito_4_id: "",
    noticias_ambito_4_titulo: "",
    noticias_ambito_4_foto: "",

    noticias_cronista_0_id: "",
    noticias_cronista_0_titulo: "",
    noticias_cronista_0_foto: "",

    noticias_cronista_1_id: "",
    noticias_cronista_1_titulo: "",
    noticias_cronista_1_foto: "",

    noticias_cronista_2_id: "",
    noticias_cronista_2_titulo: "",
    noticias_cronista_2_foto: "",

    noticias_cronista_3_id: "",
    noticias_cronista_3_titulo: "",
    noticias_cronista_3_foto: "",

    noticias_cronista_4_id: "",
    noticias_cronista_4_titulo: "",
    noticias_cronista_4_foto: "",

    noticias_pagina12_0_id: "",
    noticias_pagina12_0_titulo: "",
    noticias_pagina12_0_foto: "",

    noticias_pagina12_1_id: "",
    noticias_pagina12_1_titulo: "",
    noticias_pagina12_1_foto: "",

    noticias_pagina12_2_id: "",
    noticias_pagina12_2_titulo: "",
    noticias_pagina12_2_foto: "",

    noticias_pagina12_3_id: "",
    noticias_pagina12_3_titulo: "",
    noticias_pagina12_3_foto: "",

    noticias_pagina12_4_id: "",
    noticias_pagina12_4_titulo: "",
    noticias_pagina12_4_foto: "",

    noticias_tn_0_id: "",
    noticias_tn_0_titulo: "",
    noticias_tn_0_foto: "",

    noticias_tn_1_id: "",
    noticias_tn_1_titulo: "",
    noticias_tn_1_foto: "",

    noticias_tn_2_id: "",
    noticias_tn_2_titulo: "",
    noticias_tn_2_foto: "",

    noticias_tn_3_id: "",
    noticias_tn_3_titulo: "",
    noticias_tn_3_foto: "",

    noticias_tn_4_id: "",
    noticias_tn_4_titulo: "",
    noticias_tn_4_foto: "",
  }),

  methods: {
    trunca_cadena(texto) {
      if (texto.length > 100) {
        texto = texto.substring(0, 100) + "...";
      }
      return texto;
    },
    limpiaTexto(titulo) {
      titulo = titulo.toLowerCase();      
      titulo = titulo.replace(/[^\w\d\s]/g, "");
      titulo = titulo.replace(/[\s]/g, "-");
      titulo = titulo.substring(0, 100);
      return titulo;
    },
  },

  async mounted() {
    try {
      const res = await axios.get("https://titulos.com.ar/titulos_002.json");

      this.noticias_ambito_0_id = res.data.resultados.ambito[0][0][0];
      this.noticias_ambito_0_titulo = res.data.resultados.ambito[0][0][1];
      this.noticias_ambito_0_foto = res.data.resultados.ambito[0][0][3];

      this.noticias_ambito_1_id = res.data.resultados.ambito[0][1][0];
      this.noticias_ambito_1_titulo = res.data.resultados.ambito[0][1][1];
      this.noticias_ambito_1_foto = res.data.resultados.ambito[0][1][3];

      this.noticias_ambito_2_id = res.data.resultados.ambito[0][2][0];
      this.noticias_ambito_2_titulo = res.data.resultados.ambito[0][2][1];
      this.noticias_ambito_2_foto = res.data.resultados.ambito[0][2][3];

      this.noticias_ambito_3_id = res.data.resultados.ambito[0][3][0];
      this.noticias_ambito_3_titulo = res.data.resultados.ambito[0][3][1];
      this.noticias_ambito_3_foto = res.data.resultados.ambito[0][3][3];

      this.noticias_ambito_4_id = res.data.resultados.ambito[0][4][0];
      this.noticias_ambito_4_titulo = res.data.resultados.ambito[0][4][1];
      this.noticias_ambito_4_foto = res.data.resultados.ambito[0][4][3];

      this.noticias_cronista_0_id = res.data.resultados.cronista[0][0][0];
      this.noticias_cronista_0_titulo = res.data.resultados.cronista[0][0][1];
      this.noticias_cronista_0_foto = res.data.resultados.cronista[0][0][3];

      this.noticias_cronista_1_id = res.data.resultados.cronista[0][1][0];
      this.noticias_cronista_1_titulo = res.data.resultados.cronista[0][1][1];
      this.noticias_cronista_1_foto = res.data.resultados.cronista[0][1][3];

      this.noticias_cronista_2_id = res.data.resultados.cronista[0][2][0];
      this.noticias_cronista_2_titulo = res.data.resultados.cronista[0][2][1];
      this.noticias_cronista_2_foto = res.data.resultados.cronista[0][2][3];

      this.noticias_cronista_3_id = res.data.resultados.cronista[0][3][0];
      this.noticias_cronista_3_titulo = res.data.resultados.cronista[0][3][1];
      this.noticias_cronista_3_foto = res.data.resultados.cronista[0][3][3];

      this.noticias_cronista_4_id = res.data.resultados.cronista[0][4][0];
      this.noticias_cronista_4_titulo = res.data.resultados.cronista[0][4][1];
      this.noticias_cronista_4_foto = res.data.resultados.cronista[0][4][3];

      this.noticias_pagina12_0_id = res.data.resultados.pagina12[0][0][0];
      this.noticias_pagina12_0_titulo = res.data.resultados.pagina12[0][0][1];
      this.noticias_pagina12_0_foto = res.data.resultados.pagina12[0][0][3];

      this.noticias_pagina12_1_id = res.data.resultados.pagina12[0][1][0];
      this.noticias_pagina12_1_titulo = res.data.resultados.pagina12[0][1][1];
      this.noticias_pagina12_1_foto = res.data.resultados.pagina12[0][1][3];

      this.noticias_pagina12_2_id = res.data.resultados.pagina12[0][2][0];
      this.noticias_pagina12_2_titulo = res.data.resultados.pagina12[0][2][1];
      this.noticias_pagina12_2_foto = res.data.resultados.pagina12[0][2][3];

      this.noticias_pagina12_3_id = res.data.resultados.pagina12[0][3][0];
      this.noticias_pagina12_3_titulo = res.data.resultados.pagina12[0][3][1];
      this.noticias_pagina12_3_foto = res.data.resultados.pagina12[0][3][3];

      this.noticias_pagina12_4_id = res.data.resultados.pagina12[0][4][0];
      this.noticias_pagina12_4_titulo = res.data.resultados.pagina12[0][4][1];
      this.noticias_pagina12_4_foto = res.data.resultados.pagina12[0][4][3];

      this.noticias_tn_0_id = res.data.resultados.tn[0][0][0];
      this.noticias_tn_0_titulo = res.data.resultados.tn[0][0][1];
      this.noticias_tn_0_foto = res.data.resultados.tn[0][0][3];

      this.noticias_tn_1_id = res.data.resultados.tn[0][1][0];
      this.noticias_tn_1_titulo = res.data.resultados.tn[0][1][1];
      this.noticias_tn_1_foto = res.data.resultados.tn[0][1][3];

      this.noticias_tn_2_id = res.data.resultados.tn[0][2][0];
      this.noticias_tn_2_titulo = res.data.resultados.tn[0][2][1];
      this.noticias_tn_2_foto = res.data.resultados.tn[0][2][3];

      this.noticias_tn_3_id = res.data.resultados.tn[0][3][0];
      this.noticias_tn_3_titulo = res.data.resultados.tn[0][3][1];
      this.noticias_tn_3_foto = res.data.resultados.tn[0][3][3];

      this.noticias_tn_4_id = res.data.resultados.tn[0][4][0];
      this.noticias_tn_4_titulo = res.data.resultados.tn[0][4][1];
      this.noticias_tn_4_foto = res.data.resultados.tn[0][4][3];

      //console.log(this.noticias);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>



<style scoped>
.v-card__subtitle,
.v-card__text {
  padding: 25px !important;
}

.v-card__title {
  padding: 15px !important;
}
</style>