<template>
  <v-container class="lighten-5 container">

    <v-row class="mb-6" no-gutters>
      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/bae_03.svg" src="/img/bae_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/BAE Negocios/'+limpiaTexto(noticias_baenegocios_0_titulo)+'/'+noticias_baenegocios_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_baenegocios_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/BAE Negocios/'+limpiaTexto(noticias_baenegocios_1_titulo)+'/'+noticias_baenegocios_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_baenegocios_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/BAE Negocios/'+limpiaTexto(noticias_baenegocios_2_titulo)+'/'+noticias_baenegocios_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_baenegocios_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/BAE Negocios/'+limpiaTexto(noticias_baenegocios_3_titulo)+'/'+noticias_baenegocios_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_baenegocios_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/BAE Negocios/'+limpiaTexto(noticias_baenegocios_4_titulo)+'/'+noticias_baenegocios_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_baenegocios_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/minutouno_03.svg" src="/img/minutouno_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Minuto Uno/'+limpiaTexto(noticias_minutouno_0_titulo)+'/'+noticias_minutouno_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_minutouno_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Minuto Uno/'+limpiaTexto(noticias_minutouno_1_titulo)+'/'+noticias_minutouno_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_minutouno_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Minuto Uno/'+limpiaTexto(noticias_minutouno_2_titulo)+'/'+noticias_minutouno_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_minutouno_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Minuto Uno/'+limpiaTexto(noticias_minutouno_3_titulo)+'/'+noticias_minutouno_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_minutouno_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Minuto Uno/'+limpiaTexto(noticias_minutouno_4_titulo)+'/'+noticias_minutouno_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_minutouno_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/la_capital_03.svg" src="/img/la_capital_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/La Capital/'+limpiaTexto(noticias_lacapital_0_titulo)+'/'+noticias_lacapital_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_lacapital_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/La Capital/'+limpiaTexto(noticias_lacapital_1_titulo)+'/'+noticias_lacapital_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_lacapital_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/La Capital/'+limpiaTexto(noticias_lacapital_2_titulo)+'/'+noticias_lacapital_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_lacapital_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/La Capital/'+limpiaTexto(noticias_lacapital_3_titulo)+'/'+noticias_lacapital_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_lacapital_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/La Capital/'+limpiaTexto(noticias_lacapital_4_titulo)+'/'+noticias_lacapital_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_lacapital_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/mdz_03.svg" src="/img/mdz_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/MDZ/'+limpiaTexto(noticias_mdzonline_0_titulo)+'/'+noticias_mdzonline_0_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_mdzonline_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/MDZ/'+limpiaTexto(noticias_mdzonline_1_titulo)+'/'+noticias_mdzonline_1_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_mdzonline_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/MDZ/'+limpiaTexto(noticias_mdzonline_2_titulo)+'/'+noticias_mdzonline_2_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_mdzonline_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/MDZ/'+limpiaTexto(noticias_mdzonline_3_titulo)+'/'+noticias_mdzonline_3_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_mdzonline_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/MDZ/'+limpiaTexto(noticias_mdzonline_4_titulo)+'/'+noticias_mdzonline_4_id}">
              <div class="listyle">

                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_mdzonline_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>


<script>

import axios from "axios";
export default {
  name: "Titulos",
 
  data: () => ({
    medios1: [
      { medio: "ambito" },
      { medio: "clarin" },
      { medio: "lanacion" },
      { medio: "infobae" },
    ],
    medios2: [
      { medio: "cronista" },
      { medio: "cronica" },
      { medio: "baenegocios" },
      { medio: "eldestape" },
    ],

    noticias_baenegocios_0_id: "",
    noticias_baenegocios_0_titulo: "",
    noticias_baenegocios_0_foto: "",

    noticias_baenegocios_1_id: "",
    noticias_baenegocios_1_titulo: "",
    noticias_baenegocios_1_foto: "",

    noticias_baenegocios_2_id: "",
    noticias_baenegocios_2_titulo: "",
    noticias_baenegocios_2_foto: "",

    noticias_baenegocios_3_id: "",
    noticias_baenegocios_3_titulo: "",
    noticias_baenegocios_3_foto: "",

    noticias_baenegocios_4_id: "",
    noticias_baenegocios_4_titulo: "",
    noticias_baenegocios_4_foto: "",

    noticias_minutouno_0_id: "",
    noticias_minutouno_0_titulo: "",
    noticias_minutouno_0_foto: "",

    noticias_minutouno_1_id: "",
    noticias_minutouno_1_titulo: "",
    noticias_minutouno_1_foto: "",

    noticias_minutouno_2_id: "",
    noticias_minutouno_2_titulo: "",
    noticias_minutouno_2_foto: "",

    noticias_minutouno_3_id: "",
    noticias_minutouno_3_titulo: "",
    noticias_minutouno_3_foto: "",

    noticias_minutouno_4_id: "",
    noticias_minutouno_4_titulo: "",
    noticias_minutouno_4_foto: "",

    noticias_lacapital_0_id: "",
    noticias_lacapital_0_titulo: "",
    noticias_lacapital_0_foto: "",

    noticias_lacapital_1_id: "",
    noticias_lacapital_1_titulo: "",
    noticias_lacapital_1_foto: "",

    noticias_lacapital_2_id: "",
    noticias_lacapital_2_titulo: "",
    noticias_lacapital_2_foto: "",

    noticias_lacapital_3_id: "",
    noticias_lacapital_3_titulo: "",
    noticias_lacapital_3_foto: "",

    noticias_lacapital_4_id: "",
    noticias_lacapital_4_titulo: "",
    noticias_lacapital_4_foto: "",

    noticias_mdzonline_0_id: "",
    noticias_mdzonline_0_titulo: "",
    noticias_mdzonline_0_foto: "",

    noticias_mdzonline_1_id: "",
    noticias_mdzonline_1_titulo: "",
    noticias_mdzonline_1_foto: "",

    noticias_mdzonline_2_id: "",
    noticias_mdzonline_2_titulo: "",
    noticias_mdzonline_2_foto: "",

    noticias_mdzonline_3_id: "",
    noticias_mdzonline_3_titulo: "",
    noticias_mdzonline_3_foto: "",

    noticias_mdzonline_4_id: "",
    noticias_mdzonline_4_titulo: "",
    noticias_mdzonline_4_foto: "",
  }),

  methods: {
    trunca_cadena(texto) {
      if (texto.length > 100) {
        texto = texto.substring(0, 100) + "...";
      }
      return texto;
    },
    limpiaTexto(titulo) {
      titulo = titulo.toLowerCase();      
      titulo = titulo.replace(/[^\w\d\s]/g, "");
      titulo = titulo.replace(/[\s]/g, "-");
      titulo = titulo.substring(0, 100);
      return titulo;
    },
  },

  async mounted() {
    try {
      const res = await axios.get("https://titulos.com.ar/titulos_003.json");

      this.noticias_baenegocios_0_id = res.data.resultados.baenegocios[0][0][0];
      this.noticias_baenegocios_0_titulo =
        res.data.resultados.baenegocios[0][0][1];
      this.noticias_baenegocios_0_foto =
        res.data.resultados.baenegocios[0][0][3];

      this.noticias_baenegocios_1_id = res.data.resultados.baenegocios[0][1][0];
      this.noticias_baenegocios_1_titulo =
        res.data.resultados.baenegocios[0][1][1];
      this.noticias_baenegocios_1_foto =
        res.data.resultados.baenegocios[0][1][3];

      this.noticias_baenegocios_2_id = res.data.resultados.baenegocios[0][2][0];
      this.noticias_baenegocios_2_titulo =
        res.data.resultados.baenegocios[0][2][1];
      this.noticias_baenegocios_2_foto =
        res.data.resultados.baenegocios[0][2][3];

      this.noticias_baenegocios_3_id = res.data.resultados.baenegocios[0][3][0];
      this.noticias_baenegocios_3_titulo =
        res.data.resultados.baenegocios[0][3][1];
      this.noticias_baenegocios_3_foto =
        res.data.resultados.baenegocios[0][3][3];

      this.noticias_baenegocios_4_id = res.data.resultados.baenegocios[0][4][0];
      this.noticias_baenegocios_4_titulo =
        res.data.resultados.baenegocios[0][4][1];
      this.noticias_baenegocios_4_foto =
        res.data.resultados.baenegocios[0][4][3];

      this.noticias_minutouno_0_id = res.data.resultados.minutouno[0][0][0];
      this.noticias_minutouno_0_titulo = res.data.resultados.minutouno[0][0][1];
      this.noticias_minutouno_0_foto = res.data.resultados.minutouno[0][0][3];

      this.noticias_minutouno_1_id = res.data.resultados.minutouno[0][1][0];
      this.noticias_minutouno_1_titulo = res.data.resultados.minutouno[0][1][1];
      this.noticias_minutouno_1_foto = res.data.resultados.minutouno[0][1][3];

      this.noticias_minutouno_2_id = res.data.resultados.minutouno[0][2][0];
      this.noticias_minutouno_2_titulo = res.data.resultados.minutouno[0][2][1];
      this.noticias_minutouno_2_foto = res.data.resultados.minutouno[0][2][3];

      this.noticias_minutouno_3_id = res.data.resultados.minutouno[0][3][0];
      this.noticias_minutouno_3_titulo = res.data.resultados.minutouno[0][3][1];
      this.noticias_minutouno_3_foto = res.data.resultados.minutouno[0][3][3];

      this.noticias_minutouno_4_id = res.data.resultados.minutouno[0][4][0];
      this.noticias_minutouno_4_titulo = res.data.resultados.minutouno[0][4][1];
      this.noticias_minutouno_4_foto = res.data.resultados.minutouno[0][4][3];

      this.noticias_lacapital_0_id = res.data.resultados.lacapital[0][0][0];
      this.noticias_lacapital_0_titulo = res.data.resultados.lacapital[0][0][1];
      this.noticias_lacapital_0_foto = res.data.resultados.lacapital[0][0][3];

      this.noticias_lacapital_1_id = res.data.resultados.lacapital[0][1][0];
      this.noticias_lacapital_1_titulo = res.data.resultados.lacapital[0][1][1];
      this.noticias_lacapital_1_foto = res.data.resultados.lacapital[0][1][3];

      this.noticias_lacapital_2_id = res.data.resultados.lacapital[0][2][0];
      this.noticias_lacapital_2_titulo = res.data.resultados.lacapital[0][2][1];
      this.noticias_lacapital_2_foto = res.data.resultados.lacapital[0][2][3];

      this.noticias_lacapital_3_id = res.data.resultados.lacapital[0][3][0];
      this.noticias_lacapital_3_titulo = res.data.resultados.lacapital[0][3][1];
      this.noticias_lacapital_3_foto = res.data.resultados.lacapital[0][3][3];

      this.noticias_lacapital_4_id = res.data.resultados.lacapital[0][4][0];
      this.noticias_lacapital_4_titulo = res.data.resultados.lacapital[0][4][1];
      this.noticias_lacapital_4_foto = res.data.resultados.lacapital[0][4][3];

      this.noticias_mdzonline_0_id = res.data.resultados.mdzonline[0][0][0];
      this.noticias_mdzonline_0_titulo = res.data.resultados.mdzonline[0][0][1];
      this.noticias_mdzonline_0_foto = res.data.resultados.mdzonline[0][0][3];

      this.noticias_mdzonline_1_id = res.data.resultados.mdzonline[0][1][0];
      this.noticias_mdzonline_1_titulo = res.data.resultados.mdzonline[0][1][1];
      this.noticias_mdzonline_1_foto = res.data.resultados.mdzonline[0][1][3];

      this.noticias_mdzonline_2_id = res.data.resultados.mdzonline[0][2][0];
      this.noticias_mdzonline_2_titulo = res.data.resultados.mdzonline[0][2][1];
      this.noticias_mdzonline_2_foto = res.data.resultados.mdzonline[0][2][3];

      this.noticias_mdzonline_3_id = res.data.resultados.mdzonline[0][3][0];
      this.noticias_mdzonline_3_titulo = res.data.resultados.mdzonline[0][3][1];
      this.noticias_mdzonline_3_foto = res.data.resultados.mdzonline[0][3][3];

      this.noticias_mdzonline_4_id = res.data.resultados.mdzonline[0][4][0];
      this.noticias_mdzonline_4_titulo = res.data.resultados.mdzonline[0][4][1];
      this.noticias_mdzonline_4_foto = res.data.resultados.mdzonline[0][4][3];

      //console.log(this.noticias);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>



<style scoped>
.v-card__subtitle,
.v-card__text {
  padding: 25px !important;
}

.v-card__title {
  padding: 15px !important;
}
</style>